import { useParams } from "react-router-dom";
import useApi from "storybook-dashboard/utils/fetching";
import Im from "immutable";
import { listToMap } from "../metrics/node";

/* 
    Get the Project, Tracker, Reporting Package and Report ID's from the URL.
    Uses SWR to rapidly get parameters from cached API data
*/

export const useFullIds = () => {
  const { _companyId, _projectId, _refId, _commitId } = useParams();

  let { data: project } = useApi(`/api/project/{_projectId}/`, { _projectId });
  let projectId = project?.get("uid");
  let trackerId = project
    ?.get("trackers")
    ?.filter((i) => i.get("name") === "sustainability_tool")
    ?.first()
    ?.get("uid");

  let { data: company } = useApi(`/api/company/{_companyId}/`, { _companyId });
  let companyId = company?.get("uid") || project?.getIn(["company", "uid"]);

  let { data: tracker } = useApi(`/api/tracker/{trackerId}/`, { trackerId });
  let rootRefId = tracker?.getIn(["root_master", "uid"]);

  let __refId = _refId || rootRefId;
  let { data: ref } = useApi(`/api/tracker/{trackerId}/ref/{__refId}/`, { trackerId, __refId });
  let refId = _refId ? ref?.get("uid") : rootRefId;

  let { data: rootRef } = useApi(`/api/tracker/{trackerId}/ref/{rootRefId}/`, { trackerId, rootRefId });
  let cref = refId ? ref : rootRef;

  // Are we on the root
  let crefId = cref?.get("uid");

  let isRootRef = refId == rootRefId;

  let __commitId = _commitId || ref?.getIn(["latest_commit", "uid"]);
  let { data: commit } = useApi(`/api/tracker/{trackerId}/commit/{__commitId}/`, { trackerId, __commitId });
  let commitId = commit?.get("uid");

  return { companyId, projectId, trackerId, crefId, refId, commitId, isRootRef, company, project, tracker, commit };
};

export const useProjectPermissions = () => {
  let { projectId } = useFullIds();
  let { data: permissions, isLoading } = useApi(`/api/project/{projectId}/permission/`, { projectId });
  let { data: projectDisciplines } = useApi("/api/project/{projectId}/discipline/", { projectId });

  const isApprover = (commit) => {
    if (permissions?.get("is_admin")) return true;

    let projectDisciplineMap = listToMap(projectDisciplines, "uid");
    let projectBaseDisciplineMap = listToMap(projectDisciplines, "base_uid");
    let userDisciplineMap = listToMap(permissions.get("project_disciplines"), "uid");

    let disciplineId = commit.get("discipline");
    let approverId = projectBaseDisciplineMap.get(disciplineId)?.get("approver");

    return projectDisciplineMap?.has(approverId) ? userDisciplineMap.has(approverId) : false;
  };

  const isResponsible = (commit) => {
    if (permissions?.get("is_admin")) return true;
    let userDisciplineMap = listToMap(permissions.get("project_disciplines"), "base_uid");
    let responsibleDisciplineId = commit.get("discipline");
    return userDisciplineMap.has(responsibleDisciplineId) || isApprover();
  };

  return { permissions, isLoading, isApprover, isResponsible, isAdmin: permissions?.get("is_admin") };
};

export const useTenantMeta = () => {
  return useApi(`/api/tenant/meta/`);
};

export const useReportIndicators = () => {
  let { trackerId, commitId } = useFullIds();
  let { data, isLoading } = useApi(`/api/tracker/{trackerId}/commit/{commitId}/indicator/`, { trackerId, commitId });
  return { data, isLoading };
};

export const useReportMetricValues = () => {
  let { trackerId, commitId } = useFullIds();
  let { data: values } = useApi("/api/tracker/{trackerId}/commit/{commitId}/value/", { trackerId, commitId });

  let baseMetricValueMap = values.reduce((a, c) => {
    let baseMetricId = c.getIn(["metric", "metric", "uid"]);
    return a.set(baseMetricId, c);
  }, Im.Map());

  console.log("useReportMetricValues", baseMetricValueMap?.toJS());

  return { baseMetricValueMap };
};
